.tnc_container {
    padding: 0 16px;
}

.tnc_container h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.5;
    margin: 16px 0;
}

.tnc_container h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.5;
    margin: 16px 0;
}

.tnc_container h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    margin: 16px 0;
}

.tnc_container h4 {
    font-size: 19px;
    font-weight: 700;
    line-height: 1.5;
    margin: 16px 0;
}

.tnc_container p {
    font-size: 16px;
    line-height: 1.5;
    margin: 16px 0;
}

.tnc_container table {
    border-collapse: collapse;
    width: 100%;
}

.tnc_container table td {
    border: 1px solid #000;
    padding: 8px;
}

.tnc_container table th {
    border: 1px solid #000;
    padding: 8px;
    text-align: center;
}

.tnc_container ul {
    list-style: square;
    margin: 16px 0;
    padding-left: 32px;
}

.tnc_container li {
    margin: 8px 0;
}

.tnc_container p b {
    font-weight: 700;
    color: #3c3c3c !important;
}

.tnc_container a {
    color: rgb(48, 48, 241) !important;
}

.tnc_container ol {
    list-style: decimal;
    margin: 16px 0;
    padding-left: 16px;
}
