@import "react-big-calendar/lib/sass/styles";

.calendar-container {
    padding: 20px;
    height: 100vh; /* Use 100% of the viewport height */
    width: 100vw; /* Use 100% of the viewport width */
    display: flex;
    flex-direction: column;
}

.react-big-calendar {
    flex-grow: 1; /* Allows the calendar to fill the container */
    overflow: hidden; /* Prevents overflow issues */
}

.custom-day {
    color: #ccc;
}

/* Centering date numbers in each cell */
.rbc-day-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    &.rbc-today {
        background-color: unset;
    }
}

.rbc-off-range-bg {
    background-color: transparent;
}

.rbc-event-content {
    color: #000 !important
}

/* Alternatively, if the date itself is wrapped in an element */
.rbc-date-cell {
    color: #5e5e5e;
    padding-top: 12px;
    text-align: center;
    /* Additional styling to vertically center if needed */
    &.rbc-off-range {
        color: #ddd;
    }
}

.rbc-header {
    /* border-bottom-color: transparent; */
    & + & {
        border-left-color: transparent;
    }
}

.rbc-now button.rbc-button-link {
    background: linear-gradient(90deg, #0f4c81, #5684ae);
    color: #e4f6ed;
    border-radius: 999px;
    padding: 4px;
}

.agenda-calendar {
    & .rbc-header {
        display: none;
    }

    & .rbc-agenda-view table.rbc-agenda-table {
        border-color: transparent;
    }

    & tbody tr {
        border-radius: 8px;
        opacity: 1;
        display: block;
    }

    & .mobileView {
        display: flex;
        margin: 16px 0px;
        &:first-child {
            margin: 0px 0px 16px 0px;
        }
    }
}

/* Styles for screens wider than 768px */
@media (min-width: 768px) {
    .calendar-container {
        padding: 40px;
    }
}

/* Styles for mobile devices */
@media (max-width: 768px) {
    .calendar-container {
        padding: 10px;
    }

    .rbc-header {
        border-bottom-color: transparent;
    }

    .rbc-month-view {
        flex: unset !important;
        height: 226px !important;
        padding: 0px 60px !important;
        border-color: transparent !important;
    }

    /* Adjust the day size and padding */
    .rbc-day-bg,
    .rbc-date-cell {
        padding: 8px;
        font-size: 16px; /* Adjust the font size as needed */
        border-color: transparent !important;
    }

    .rbc-month-row + .rbc-month-row {
        border-color: transparent !important;
    }

    /* Adjust selected day styling */
    .rbc-day-bg .rbc-selected {
        background-color: blue; /* Replace with the color you want */
        color: white;
    }

    /* Adjust the month label */
    .rbc-toolbar-label {
        font-size: 18px; /* Adjust the font size as needed */
        font-weight: bold;
    }

    /* Show minimal navigation buttons */
    .rbc-btn-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}
