#__next {
    height: 100%;
}

html {
    overflow: inherit !important;
    padding: 0 !important;
}

body {
    overflow-x: hidden;
}

.required-label:after {
    content: "*";
    color: red;
    padding-left: 2px;
}

.error {
    border: 1px solid red;
}

.tox-statusbar {
    display: none !important;
}

.container .row {
    margin: 20px;
    text-align: center;
}

.container .row img {
    margin: 0 20px;
}

.circle-container .why {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10em;
    height: 4em;
    margin: -2em;
}

.image-shadow {
    border-radius: 50%;
    padding: 4px;
    box-shadow: 0 -4px 9px 0 #1d1d1d40 inset;
}

.deg0 {
    transform: translate(12em);
}

.deg45 {
    transform: rotate(45deg) translate(12em) rotate(-45deg);
}

.deg130 {
    transform: rotate(135deg) translate(12em) rotate(-135deg);
}

.deg135 {
    transform: rotate(135deg) translate(12em) rotate(-135deg);
}

.deg180 {
    transform: translate(-12em);
}

.deg225 {
    transform: rotate(220deg) translate(12em) rotate(-220deg);
}

.deg315 {
    transform: rotate(322deg) translate(12em) rotate(-322deg);
}

.deg160 {
    transform: rotate(165deg) translate(12em) rotate(-165deg);
}

.deg40 {
    transform: rotate(40deg) translate(12em) rotate(-40deg);
}

.deg190 {
    transform: rotate(190deg) translate(12em) rotate(-190deg);
}

.deg345 {
    transform: rotate(350deg) translate(12em) rotate(-350deg);
}

.deg15 {
    transform: rotate(15deg) translate(12em) rotate(-15deg);
}

.left-position {
    left: -4% !important;
}

.hex {
    display: block;
    margin: 0 auto;
    position: absolute;
    top: -40%;
    left: -2%;
    width: 145px;
    height: 125px;
    background: white;
    box-sizing: border-box;
    clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.hex video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.3;
    clip-path: polygon(0% 50%, 25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%);
}

.tac-logo {
    position: absolute;
    top: 32%;
    width: 60%;
    height: 50%;
    left: 15%;
}

.puzzle-container {
    position: relative;
    width: 24em;
    height: 24em;
    padding: 2.8em;
    border-radius: 50%;
}

.puzzle-container .center {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18em;
    height: 4em;
    margin: -2em;
}

.puzzle-container .center {
    top: 10% !important;
    left: 25% !important;
}

.puzzle-top {
    position: absolute;
    top: 77%;
    left: 20%;
    width: 50%;
}

.top-hover:hover .puzzle-top {
    transform: rotate(15deg);
}

.puzzle-white {
    width: 89%;
}

.puzzle-angles {
    position: absolute;
    top: 45%;
    left: -6%;
}

.header {
    background-color: #fff;
    position: fixed;
    z-index: 15;
    margin: 0 auto;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.header-auth {
    background-color: #fff;
    z-index: 10;
    margin: 0 auto;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
}

.footer {
    width: 100%;
}

.req-list ul {
    list-style: circle;
}

.req-list ol {
    list-style: decimal;
}

.arrow-up-slate-600:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #475569;
    position: absolute;
    top: -10px;
    left: 30px;
    content: "";
}

.schedule-interview-calendar {
    border-color: #14808d !important;
    border-radius: 0.5rem;
    overflow: hidden;
    width: 100% !important;
    padding: 0.5rem;
}

.schedule-interview-calendar .react-calendar__tile:enabled:hover,
.schedule-interview-calendar .react-calendar__tile:enabled:focus {
    background-color: #b9dde1 !important;
    border-radius: 0.5rem;
}

.schedule-interview-calendar .react-calendar__tile--active {
    background-color: #8bc6ce !important;
    border-radius: 0.5rem;
    color: black !important;
}

.react-calendar__navigation__label:hover,
.react-calendar__navigation__arrow:hover {
    background-color: #b9dde1 !important;
    border-radius: 0.5rem;
}

.react-calendar__tile--now {
    background: none !important;
}

.react-calendar__navigation__arrow:disabled,
.react-calendar__tile:disabled,
.react-calendar__tile--now:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.schedule-interview-calendar .react-calendar__tile--hasActive {
    background-color: #8bc6ce !important;
    border-radius: 0.5rem;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

@keyframes modal-video {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes modal-video-inner {
    from {
        transform: translate(0, 100px);
    }

    to {
        transform: translate(0, 0);
    }
}

.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
    opacity: 0;
}

.modal-video-effect-exit .modal-video-movie-wrap {
    -ms-transform: translate(0, 100px);
    transform: translate(0, 100px);
}

.modal-video-body {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.modal-video-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@media (orientation: landscape) {
    .modal-video-inner {
        padding: 10px 60px;
        box-sizing: border-box;
    }
}

.modal-video-movie-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    background-color: #333;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video-inner;
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -moz-transition: -moz-transform 0.3s ease-out;
    -ms-transition: -ms-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
}

.modal-video-movie-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal-video-close-btn {
    position: absolute;
    z-index: 2;
    top: -45px;
    right: 0;
    display: inline-block;
    width: 35px;
    height: 35px;
    overflow: hidden;
    border: none;
    background: transparent;
}

@media (orientation: landscape) {
    .modal-video-close-btn {
        top: 0;
        right: -45px;
    }
}

.modal-video-close-btn:before {
    transform: rotate(45deg);
}

.modal-video-close-btn:after {
    transform: rotate(-45deg);
}

.modal-video-close-btn:before,
.modal-video-close-btn:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    top: 50%;
    left: 0;
    background: #fff;
    border-radius: 5px;
    margin-top: -6px;
}

.button-change {
    position: fixed;
    left: 50%;
    top: 74%;
    margin-left: -37px;
}

#hubspotForm form .hs-button {
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    background-color: rgb(23 143 157/1);
    color: #ffffff;
    font-size: 1rem;
    text-transform: none;
    border-radius: 1.5rem;
    padding: 0.5rem 3.5rem;
    margin: 1rem 0 0.5rem;
    width: 100%;
}

#hubspotForm .hs-button:hover {
    background-color: rgb(69 165 176);
}

#hubspotForm form .hs-button {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    transition: all 0.15s linear;
}

#hubspotForm form .hs-button {
    -webkit-appearance: button;
    background-image: none;
}

#hubspotForm .hs-input.invalid.error {
    border: 1px solid #ef6b51;
}

#hubspotForm form input,
#hubspotForm form select,
#hubspotForm form textarea {
    background-color: rgb(243 244 246/1);
    color: black;
    border-style: none;
    border-radius: 0.125rem;
    width: 100%;
}

#hubspotForm form label {
    display: block;
    font-size: 0.875rem !important;
    line-height: 1.25rem;
    margin-bottom: 0.45rem;
    color: rgb(75 85 99);
    margin-top: 0.5rem;
}

#hubspotForm .hs-form-private fieldset.form-columns-2 .input {
    margin-right: 1rem !important;
}

#hubspotForm .hs-form-private fieldset.form-columns-1 .hs-input {
    width: 100% !important;
}

#hubspotForm .hs-form-required {
    color: #ef6b51 !important;
}

#hubspotForm .hs-error-msg {
    line-height: 1;
    font-size: 0.75rem !important;
    margin-top: 2px !important;
    color: #ef6b51;
}

#hubspotForm .legal-consent-container p {
    font-size: 0.75rem !important;
    line-height: 1.5rem;
    color: rgb(0 0 0/1) !important;
    margin: 0.5rem 0;
}

#hubspotForm .hubspot-link__container.sproket {
    display: none;
}

@media screen and (min-width: 1024px) {
    .work-image {
        width: 50%;
        clip-path: polygon(12% 0, 100% 0, 100% 100%, 0 100%, 0 210%);
    }

    #hubspotForm form .hs-button {
        width: auto;
    }
}

#hubspotForm .hs_error_rollup {
    display: none;
}

#hubspotForm form input:focus,
#hubspotForm form select:focus,
#hubspotForm form textarea:focus {
    border: 1px solid rgb(23 143 157) !important;
    --tw-ring-color: transparent !important;
}

#hubspotForm fieldset {
    max-width: 100% !important;
}

.svg-circle-bg {
    fill: none;
}

.engineer-modification-container label {
    min-width: 200px;
}

.salary-table-container label {
    min-width: auto;
}

.width-label-container label {
    min-width: 170px;
}

.english-proficiency-label label {
    width: auto;
}

.additional-candidate-container dt {
    min-width: 122px;
}

@media (min-width: 768px) {
    .additional-candidate-container dd {
        padding-left: 30px;
    }

    .addQuestionScreen > div {
        width: calc(50% - 8px);
    }
}

.experience-container dt {
    width: 238px;
}

.ellipse-testimonial {
    background-image: url("/Ellipse2.png");
}

.integration-container {
    background: url("/home/Ellipse-lg.png") no-repeat center center;
    background-size: cover;
}

.bg-ellipse {
    background: url("/home/ellipse-r.png") no-repeat center center;
}

.vjs-marker {
    position: absolute;
    background: #45A5B0;
    width: 5px;
    height: 100%;
    top: -5%;
    z-index: 30;
    margin-left: -3px;
}

.vjs-marker:hover span {
    opacity: 1;
}

.vjs-marker span {
    height: max-content;
    line-height: 1em;
    position: absolute;
    bottom: 15px;
    opacity: 0;
    margin-left: -20px;
    z-index: 90;
    background: rgba(0, 0, 0, 0.8);
    padding: 8px;
    font-size: 10px;
    width: max-content;
    text-align: left;
    max-width: 10rem;
}

.vjs-progress-holder.vjs-slider.vjs-slider-horizontal {
    background-color: #ccc !important;
}

.vjs-progress-holder.vjs-slider.vjs-slider-horizontal {
    font-size: 1.5em !important;
    line-height: 0.3em !important;
}

.video-js .vjs-play-progress:before {
    z-index: 40 !important;
}

.video-js .vjs-load-progress {
    background-color: #aaa !important;
}

.center-slide {
    scale: 1.3;
    transition: all 0.3s ease;
}

#responsive-section .slick-dots li.slick-active button:before {
    color: white;
    scale: 2.5;
}

#responsive-section .slick-dots button:before {
    color: #178f9d;
    scale: 2.5;
}

#showcased-section .slick-dots li.slick-active button:before {
    color: #1a909d;
    scale: 2.5;
}

#showcased-section .slick-dots button:before {
    color: #178f9d;
    scale: 2;
}

.video-js .vjs-load-progress div {
    background: #777 !important;
}

.vjs-poster img {
    object-fit: fill !important;
}

.ignore-tailwind-styles ul {
    list-style: initial;
    margin-left: 0;
    padding-left: 1rem;
}

.ignore-tailwind-styles ol {
    list-style: numeric;
    margin-left: 0;
    padding-left: 1rem;
}

.ignore-tailwind-styles blockquote {
    margin: 1em 0;
    padding: 0.5em 1em;
    border-left: 4px solid #ccc;
    font-style: italic;
}

.ignore-tailwind-styles h1 {
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}

.ignore-tailwind-styles a {
    color: #14808d;
    cursor: pointer;
}

.range-input {
    width: 100%;
    position: relative;
}

.range-input .range-label-start {
    position: absolute;
    top: 1.5rem;
}

.range-input input:nth-child(3) {
    position: relative;
    top: -33px;
}

.range-input .range-label-end {
    position: absolute;
    top: 1.5rem;
    right: -0.75rem;
}

input[type="range"] {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    margin: 0;
    padding: 0 1px;
    overflow: hidden;
    border: 0;
    border-radius: 1px;
    outline: none;
    background: linear-gradient(grey, grey) no-repeat center;
    background-size: 100% 2px;
    pointer-events: none;
}

input[type="range"]:active,
input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-thumb {
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    background-color: #fff;
    position: relative;
    margin: 5px 0;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: all;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.25);
    z-index: 1;
}

.end-range-bg::-webkit-slider-thumb {
    background-color: rgb(69 165 176) !important;
}

input[type="range"]::-webkit-slider-thumb::before {
    content: " ";
    display: block;
    position: absolute;
    top: 13px;
    left: 100%;
    width: 2000px;
    height: 2px;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "" !important;
    background-image: url("/video-play-button.png");
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: 55% calc(50% - 0px);
    border: none !important;
    box-shadow: none !important;
}
.vjs-text-track-display {
    position: relative !important;
}

.align-checkbox {
    text-align: start !important;
}

.currency-selector * {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
}

.currency-selector input {
    max-width: 100px !important;
}

.rotating-triangle {
    animation: rotation 9s infinite linear;
}

/*it only rotates every third of the time */
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    32% {
        transform: rotate(0deg);
    }
    33% {
        transform: rotate(120deg);
    }
    65% {
        transform: rotate(120deg);
    }
    66% {
        transform: rotate(240deg);
    }
    99% {
        transform: rotate(240deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.grecaptcha-badge {
    width: 256px;
    height: 60px;
    display: block;
    transition: left 0.3s ease 0s;
    position: fixed;
    bottom: 14px;
    left: -186px;
    box-shadow: rgb(128, 128, 128) 0 0 5px;
    border-radius: 2px;
    overflow: hidden;
    visibility: visible !important;
    z-index: 99;
}

.grecaptcha-badge:hover {
    left: 0;
}

.next-image span {
    position: static !important;
}

.question_group_table {
    table-layout: fixed;
}

.question_group_table th:first-child {
    width: 120px;
}

.question_group_table th:nth-child(2) {
    width: 160px;
}

.question_group_table th:nth-child(3) {
    width: 120px;
}

.question_group_table th:nth-child(4) {
    width: 660px;
}

.question_group_table th:nth-child(5) {
    width: 145px;
}

.question_group_table tr {
    text-align: left;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 100%;
    vertical-align: top;
}

.question_group_table td {
    padding: 0.5rem;
}

.insight_table_cell {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

.insight_table_heading {
    width: 100%;
}

.insight_table_heading span {
    padding: 0 0.5rem;
}

.question_group_table th {
    padding: 0.5rem;
}

.width-15p { 
    width: 15%; 
}  

.width-65p { 
    width: 65%; 
} 

.width-20p { 
    width: 20%; 
} 

.interview-design-generate-button-div{ 
    margin-top : 2.3em 
}

.skill-tab-max-width {
    max-width: 90%;
}

.interview-design-scrollbar::-webkit-scrollbar {
    height: 5px;
}

.interview-design-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.interview-design-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

.interview-design-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.design-question_group_table tr {
    text-align: left;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 100%;
    vertical-align: top;
}

.design-question_group_table td {
    padding: 0.5rem;
}

.insight_table_cell {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

.design-question_group_table th {
    padding: 0.5rem;
}

.insight_table {
    table-layout: fixed;
}

.insight_table tr {
    text-align: left;
    border-bottom: 1px solid #ddd;
    border-top: none;
}

.insight_table tr th:first-child {
    min-width: 200px;
    width: 50%;
}

.insight_table tr th:last-child {
    min-width: 30px;
    width: 30px;
}

/*2nd child */
.insight_table tr th:nth-child(2) {
    min-width: 200px;
    width: 25%;
}

/*3rd child */
.insight_table tr th:nth-child(3) {
    min-width: 200px;
    width: 25%;
}

.insight_table tbody tr {
    align-items: center;
}

.question_group_table tbody tr:last-child {
    border-bottom: none;
}

.insight_table tbody tr:last-child {
    border-bottom: none;
}

.insight_table td {
    padding: 0.5rem;
}

.insight_cell {
    vertical-align: top;
}

.insights_table {
    table-layout: fixed;
    border-collapse: separate;
    margin-top: -10px;
    border-spacing: 0;
}

.insights_table tr {
    text-align: left;
    width: 100%;
}

.insights_table thead tr {
    border: none;
}

.insights_table tr td {
    padding: 0.5rem;
}

/*not in insight_table_heading*/
.insights_table .insight_table_heading td {
    padding: 0.5rem 0.5rem 0.5rem 0;
}

.insights_table td:first-child {
    min-width: 200px;
    width: 20%;
}

.insights_table td:last-child {
    padding: 0;
}

.insights_table tr td:nth-child(2) {
    width: 670px;
}

.insights_table tr td:nth-child(3) {
    min-width: 200px;
    width: 25%;
}

.insights_table tbody {
    overflow-x: auto;
    min-width: max-content;
}

.insight_row {
    vertical-align: top;
}

[role="tooltip"] {
    z-index: 100;
}

.autoGrid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.prose-text-align {
    text-align: left;
}

.star-svg {
    display: inline-flex;
}

.description-parse ol,
.description-parse ul {
    list-style: revert;
}

.corner {
    height: 10px;
    aspect-ratio: 1;
    display: inline-block;
    clip-path: polygon(0 0, 100% 0, 50% 50%, 0 100%, 0 0);
}

.status {
    background: #005e54;
    min-height: 20px;
}

.remove-scrollbar::-webkit-scrollbar {
    display: none;
}

.custom-box-shadow {
    box-shadow: 0 -2px 0 rgb(31 41 55 / 1);
}

.react-daterange-picker__wrapper {
    border-radius: 5px;
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    font-size: 0.875rem;
    line-height: 2.25rem;
    height: 38px;
}

.react-daterange-picker__wrapper:focus-within {
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-opacity: 0.5;
    --tw-ring-color: rgb(139 198 206 / 0.5) !important;
    --tw-border-opacity: 1;
    border-color: rgb(92 176 186 / var(--tw-border-opacity)) !important;
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
}

.react-daterange-picker__inputGroup__input {
    background: none !important;
    border-color: transparent !important;
}

.react-daterange-picker__inputGroup__input:focus {
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: transparent;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
        var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
        calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
        var(--tw-shadow);
}

.react-daterange-picker__calendar {
    z-index: 20 !important;
}

.react-daterange-picker__calendar .react-calendar {
    overflow: hidden;
    width: 80% !important;
    padding: 0.5rem;
}

.react-daterange-picker {
    width: 100%;
}

.react-daterange-picker__inputGroup {
    padding: 0 15px !important;
}

.react-daterange-picker__button {
    padding: 4px 15px !important;
}

.react-daterange-picker__calendar .react-calendar__tile--hasActive {
    background: #006edc !important;
    color: white;
}

.react-daterange-picker__calendar .react-calendar__tile:enabled:hover,
.react-daterange-picker__calendar .react-calendar__tile:enabled:focus {
    background-color: #006edc !important;
    color: white;
}

.react-daterange-picker__calendar .react-calendar__navigation__label:hover,
.react-daterange-picker__calendar .react-calendar__navigation__arrow:hover {
    background-color: #006edc !important;
    color: white !important;
}

.react-daterange-picker__calendar .react-calendar__tile--now {
    background: #006edc !important;
}

.react-time-picker__wrapper {
    border-color: #ccc !important;
    border-radius: 5px;
    padding: 4px 8px;
}

.react-time-picker__inputGroup__input:focus {
    @apply ring-0;
    border-color: #ccc !important;
    border-radius: 2px;
}

.react-time-picker__inputGroup__divider {
    padding: 1px 1px !important;
}

.react-time-picker__clear-button__icon {
    height: 1rem;
    width: 1rem;
}

.react-time-picker__clear-button__icon:focus {
    stroke: #178f9d !important;
    outline: none;
}

.format-code code {
    color: #e83e8c;
}
.table-markdown-content {
    line-height: 1.4;
    font-size: 0.875rem;
}

.table-markdown-content h1,
.table-markdown-content h2,
.table-markdown-content h3 {
    font-weight: bold;
    margin: 0.5em 0;
    border-bottom: 1px solid #eee;
    font-size: 0.875rem;
}

.table-markdown-content ul {
    list-style-type: disc;
    margin: 0.5em 0;
    padding-left: 2em;
}

.table-markdown-content ol {
    list-style-type: decimal;
    margin: 0.5em 0;
    padding-left: 2em;
}

.table-markdown-content pre {
    border: 1px solid rgb(212, 212, 212);
    padding: 1em;
    overflow-x: auto;
    background-color: transparent;
}

.table-markdown-content blockquote {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    background-color: #f9f9f9;
    border-left: 4px solid #ccc;
    font-style: italic;
    color: #666;
}

.table-markdown-content table {
    border-collapse: collapse;
    width: 100%;
    margin: 0.5em 0;
}

.table-markdown-content table th,
.table-markdown-content table td {
    border: 1px solid #ddd;
    padding: 0.75em;
    text-align: left;
}

.table-markdown-content table th {
    background-color: #f7f7f7;
    font-weight: bold;
}

.table-markdown-content ins {
    text-decoration: underline;
    color: green;

}

.table-markdown-content mark {
    background-color: yellow;
}

.table-markdown-content code {
    color: #e83e8c;
}

.markdown-content {
    line-height: 1.8;
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3 {
    font-weight: bold;
    margin: 0.5em 0;
    color: #2c3e50;
    border-bottom: 1px solid #eee;
    font-size: revert;
}

.markdown-content ul {
    list-style-type: disc;
    margin: 0.5em 0;
    padding-left: 2em;
}

.markdown-content ol {
    list-style-type: decimal;
    margin: 0.5em 0;
    padding-left: 2em;
}

.markdown-content pre {
    border: 1px solid rgb(212, 212, 212);
    padding: 1em;
    overflow-x: auto;
    font-family: 'Courier New', Courier, monospace;
    background-color: transparent;
}

.markdown-content blockquote {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    background-color: #f9f9f9;
    border-left: 4px solid #ccc;
    font-style: italic;
    color: #666;
}

.markdown-content table {
    border-collapse: collapse;
    width: 100%;
    margin: 0.5em 0;
}

.markdown-content table th,
.markdown-content table td {
    border: 1px solid #ddd;
    padding: 0.75em;
    text-align: left;
}

.markdown-content table th {
    background-color: #f7f7f7;
    font-weight: bold;
    color: #333;
}

.markdown-content ins {
    text-decoration: underline;
    color: green;
}

.markdown-content mark {
    background-color: yellow;
    color: black;
}

.markdown-content code {
    font-family: monospace;
    color: #e83e8c;
}

.markdown-content-truncated {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 17px 7px !important;
}

#number-field::-webkit-outer-spin-button,
#number-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#number-field {
    -moz-appearance: textfield;
}

.number-field::-webkit-outer-spin-button,
.number-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.number-field {
    -moz-appearance: textfield;
}

.negotiable-upto-suffix {
    color: hsl(0, 0%, 60%);
}

.first-fold-image {
    position: relative;
    background-image:
        linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%),
        url("/first-fold.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    box-shadow: inset 0 -20px 30px rgb(254, 254, 254);
}
