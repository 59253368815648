.app .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

/* Snow Theme */
#textEditor .ql-bubble .ql-editor {
}

#textEditor .ql-editor {
    min-height: 24em;
}

#textEditor .quill {
    border: 1px solid #ccc;
    border-radius: 0.5em;
    overflow: hidden;
}

#textEditor .ql-toolbar {
    border-top: none;
    background: #fefcfc;
    text-align: center;
}

.questionTextEditor .ql-bubble .ql-editor {
}

.questionTextEditor .ql-editor {
    min-height: 6em;
}

.questionTextEditor .quill {
    border: 1px solid #ccc;
    border-radius: 0.5em;
    overflow: hidden;
}

.questionTextEditor .ql-toolbar {
    border-top: none;
    background: #fefcfc;
    text-align: center;
}
